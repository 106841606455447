.cardWrapper {

	&:global(.alignwide) {
		--max-width-grid: 85rem;

		margin-inline: 0;
		padding-inline: 0;

		@media (--bp-medium) {
			margin-inline: var(--margin-full-width);
			padding-inline: var(--spacing-2xs);
		}
	}
}
